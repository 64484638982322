import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Table, Thead, Tbody, Tr, Th, Td, Image, Skeleton, Tooltip, Text, Stack, ButtonGroup, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

function DeepVision() {
    const [file, setFile] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hoveredBoxIndex, setHoveredBoxIndex] = useState(null);
    const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8787/deep_vision/' : '/deep_vision/';

    const [sortConfig, setSortConfig] = useState({ key: 'confidence', direction: 'desc' });
    const imgRef = React.useRef(null);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(boxes.length / itemsPerPage);

    const indexOfLastBox = currentPage * itemsPerPage;
    const indexOfFirstBox = indexOfLastBox - itemsPerPage;
    const currentBoxes = boxes.slice(indexOfFirstBox, indexOfLastBox);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleUploadAndDetect = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (event) => {
            const file = event.target.files[0];
            if (!file) return;

            setBoxes([]);

            setImgSrc(URL.createObjectURL(file));
            setLoading(true);

            const formData = new FormData();
            formData.append('file', file);
            const response = await fetch(apiUrl, { method: 'POST', body: formData });
            const data = await response.json();
            sortData(data); // Sort and set here
            setLoading(false);
        };
        input.click();
    };

    const getBoxDimensions = (box) => {
        if (!imgRef.current) return {};

        const scaleX = imgRef.current.clientWidth / imgRef.current.naturalWidth;
        const scaleY = imgRef.current.clientHeight / imgRef.current.naturalHeight;
        return {
            x: box.x1 * scaleX,
            y: box.y1 * scaleY,
            width: (box.x2 - box.x1) * scaleX,
            height: (box.y2 - box.y1) * scaleY,
        };
    };

    const handleSort = (key) => {
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            setSortConfig({ key, direction: 'desc' });
        } else {
            setSortConfig({ key, direction: 'asc' });
        }

        sortData(boxes); // Re-sort when the sort config changes
    };

    useEffect(() => {
        const handleResize = () => setBoxes(prevBoxes => [...prevBoxes]);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sortData = (data) => {
        const sorted = [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        setBoxes(sorted);
    };

     return (
        <Flex p={5} direction="row" justify="center" align="center" width="100%">
            {
            (true) &&
                <Box position="relative" mr={5}>
                    {imgSrc && <Image ref={imgRef} src={imgSrc} maxW="100%" maxH="calc(100vh - 50px)" />}
                    {boxes.map((box, index) => {
                        const { x, y, width, height } = getBoxDimensions(box.box);
                        return (
                            <Tooltip key={index} label={`${box.name} - ${box.confidence.toFixed(2)}`} fontSize="md">
                                <Box key={index} position="absolute" left={x} top={y} width={width} height={height}
                                     borderWidth="2px" borderColor={(hoveredBoxIndex === null || hoveredBoxIndex === index) ? "red" : "transparent"}
                                     display="flex" justifyContent="center" alignItems="center"
                                     onMouseEnter={() => setHoveredBoxIndex(index)} onMouseLeave={() => setHoveredBoxIndex(null)}
                                     cursor="pointer">
                                </Box>
                            </Tooltip>
                        );
                    })}
                </Box>
            }
            <Box pl={4}>
                <Button onClick={handleUploadAndDetect} mb={4}>Upload and Detect</Button>

                {loading ? (
                  <Box>
                    <Skeleton height="20px" my="10px" />
                    <Skeleton height="20px" my="10px" />
                    <Skeleton height="20px" my="10px" />
                    <Skeleton height="20px" my="10px" />
                    <Skeleton height="20px" my="10px" />
                  </Box>
                ) : null}

                {
                (!loading && boxes && boxes.length > 0) &&
                <>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th cursor="pointer" onClick={() => handleSort('name')}>Name</Th>
                                <Th cursor="pointer" onClick={() => handleSort('confidence')}>Confidence</Th>
                                <Th>Bounds [x,y,width,height]</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentBoxes.map((box, index) => {
                                const globalIndex = ((currentPage - 1) * itemsPerPage) + index;
                                return (
                                    <Tr key={`[${Math.round(box.box.x1)}, ${Math.round(box.box.y1)}, ${Math.round(box.box.x2 - box.box.x1)}, ${Math.round(box.box.y2 - box.box.y1)}]`}
                                        cursor="pointer"
                                        bgColor={hoveredBoxIndex === globalIndex ? "gray.200" : "white"}
                                        onMouseEnter={() => setHoveredBoxIndex(globalIndex)}
                                        onMouseLeave={() => setHoveredBoxIndex(null)}>
                                        <Td>{box.name}</Td>
                                        <Td>{box.confidence.toFixed(2)}</Td>
                                        <Td>{`[${Math.round(box.box.x1)}, ${Math.round(box.box.y1)}, ${Math.round(box.box.x2 - box.box.x1)}, ${Math.round(box.box.y2 - box.box.y1)}]`}</Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                    <Stack spacing={4} direction="row" paddingY={4} justifyContent="center">
                        <ButtonGroup>
                            <IconButton
                                icon={<ChevronLeftIcon />}
                                isDisabled={currentPage === 1}
                                onClick={() => paginate(currentPage - 1)}
                            />
                            <IconButton
                                icon={<ChevronRightIcon />}
                                isDisabled={currentPage === totalPages}
                                onClick={() => paginate(currentPage + 1)}
                            />
                        </ButtonGroup>
                    </Stack>
                </>
                }

            </Box>
        </Flex>
    );
}

export default DeepVision;
